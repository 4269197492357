<template>
  <b-overlay
    :show="loading"
    rounded
    opacity="0.7"
    spinner-small
    spinner-variant="primary"
    class="d-inline-block w-100"
  >
    <div class="profile-view mt-3" v-if='user && user.id'>
      <div class="user">
        <h3>Pessoa Física - Responsável pelo uso do Web no dia a dia</h3>
        <div class="inputs">
          <base-input-text
            v-model="user.profile.name"
            label="Nome completo"
            placeholder="Digite o nome completo"
            block
          />

          <base-input-text
            v-model="user.profile.birthday"
            label="Data de nascimento"
            type="date"
            block
          />

          <base-input-text
            v-model="user.profile.phone"
            label="Telefone Pessoal"
            placeholder="Digite o telefone pesssoal"
            block
            v-mask="['(##) #####-####', '(##) ####-####']"
          />

          <base-input-select
            v-model="user.profile.gender"
            label="Gênero"
            placeholder="Digite o gênero"
            :options="genderOptions"
            _key="key"
            _value="value"
          />

          <base-input-text
            v-model="user.profile.cpf"
            label="CPF"
            placeholder="Digite o CPF"
            block
            v-mask="'###.###.###-##'"
          />

          <base-input-text
            v-model="user.profile.address"
            label="Endereço"
            placeholder="Digite o endereço"
            block
          />

          <base-input-text
            v-model="user.profile.number"
            label="Número"
            placeholder="Digite o número"
            block
          />

          <base-input-text
            v-model="user.profile.neighborhood"
            label="Bairro"
            placeholder="Digite o bairro"
            block
          />

          <base-input-text
            v-model="user.profile.cep"
            label="CEP"
            placeholder="Digite o CEP"
            block
            v-mask="'##.###-###'"
          />

          <base-input-text
            v-model="user.profile.city"
            label="Cidade"
            placeholder="Digite o nome da cidade"
            block
          />

          <base-input-select
            v-model="user.profile.state"
            label="Estado"
            placeholder="Selecione o estado"
            :options="stateOptions"
            _key="key"
            _value="value"
          />
        </div>
      </div>

      <h3>Pessoa Jurídica - Empresa a qual o responsável pelo uso do Web no dia a dia faz parte</h3>

      <base-input-checkox
        v-model="user.profile.associatedCompany"
        label="Não estou associado a nenhuma empresa"
      />

      <div class="company" v-if="!user.profile.associatedCompany">
        <div class="inputs">
          <base-input-text
            v-model="user.profile.company.name"
            label="Nome Empresarial"
            placeholder="Digite o nome empresarial"
            block
          />

          <base-input-text
            v-model="user.profile.company.cnpj"
            label="CNPJ"
            placeholder="Digite o CNPJ"
            block
            v-mask="'##.###.###/####-##'"
          />

          <base-input-text
            v-model="user.profile.company.phone"
            label="Telefone Comercial"
            placeholder="Digite o telefone comercial"
            block
            v-mask="['(##) #####-####', '(##) ####-####']"
          />

          <base-input-text
            v-model="user.profile.company.stateRegister"
            label="Inscrição Estadual (se houver)"
            placeholder="Digite a inscrição estadual"
            block
          />

          <base-input-text
            v-model="user.profile.company.cityRegister"
            label="Inscrição Municipal (se houver)"
            placeholder="Digite a inscrição municipal"
            block
          />

          <base-input-text
            v-model="user.profile.company.address"
            label="Endereço"
            placeholder="Digite o endereço"
            block
          />

          <base-input-text
            v-model="user.profile.company.number"
            label="Número"
            placeholder="Digite o número"
            block
          />

          <base-input-text
            v-model="user.profile.company.neighborhood"
            label="Bairro"
            placeholder="Digite o bairro"
            block
          />

          <base-input-text
            v-model="user.profile.company.cep"
            label="CEP"
            placeholder="Digite o CEP"
            block
            v-mask="'##.###-###'"
          />

          <base-input-text
            v-model="user.profile.company.city"
            label="Cidade"
            placeholder="Digite o nome da cidade"
            block
          />

          <base-input-select
            v-model="user.profile.company.state"
            label="Estado"
            placeholder="Selecione o estado"
            :options="stateOptions"
            _key="key"
            _value="value"
          />
        </div>
      </div>

      <div>
        <b-alert
          show
          variant='danger'
          :class='{ hidden: !errors }'
        >
          {{ errors }}
        </b-alert>
      </div>

      <b-overlay
        :show="loadingUpdate"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
        class="d-inline-block w-100 mb-5"
      >
        <base-button
          @click="update"
          success
          block
        >
          Atualizar
        </base-button>
      </b-overlay>
    </div>

    <template v-slot:overlay>
      <div class="text-center loading-spinner">
        <b-spinner style="width: 3rem; height: 3rem;" variant='primary' label="Large Spinner"></b-spinner>
        <h5 class='text-primary'>Buscando...</h5>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import { format } from 'date-fns'
import { mask } from 'vue-the-mask'
import { mapActions } from 'vuex'
import moment from 'moment'
import { phoneValidator } from '../utils/phoneValidator'
import { CNPJ, CPF } from '../utils/ValidateCPFCNPJ'
import { verifyCNPJExists, verifyCPFExists } from '../services/Search_CPF_CNPJ_CEP'

export default {
  directives: { mask },
  data() {
    return {
      loading: false,
      loadingUpdate: false,
      error: false,
      stateOptions: [
        { key: "Acre" , value: "Acre (AC)" },
        { key: "Alagoas" , value: "Alagoas (AL)" },
        { key: "Amapá" , value: "Amapá (AP)" },
        { key: "Amazonas" , value: "Amazonas (AM)" },
        { key: "Bahia" , value: "Bahia (BA)" },
        { key: "Ceará" , value: "Ceará (CE)" },
        { key: "Distrito Federal" , value: "Distrito Federal (DF)" },
        { key: "Espírito Santo" , value: "Espírito Santo (ES)" },
        { key: "Goiás" , value: "Goiás (GO)" },
        { key: "Maranhão" , value: "Maranhão (MA)" },
        { key: "Mato Grosso " , value: "Mato Grosso (MT)" },
        { key: "Mato Grosso do Sul" , value: "Mato Grosso do Sul (MS)" },
        { key: "Minas Gerais" , value: "Minas Gerais (MG)" },
        { key: "Pará" , value: "Pará (PA)" },
        { key: "Paraíba" , value: "Paraíba (PB)" },
        { key: "Paraná" , value: "Paraná (PR)" },
        { key: "Pernambuco" , value: "Pernambuco (PE)" },
        { key: "Piauí" , value: "Piauí (PI)" },
        { key: "Rio de Janeiro" , value: "Rio de Janeiro (RJ)" },
        { key: "Rio Grande do Norte" , value: "Rio Grande do Norte (RN)" },
        { key: "Rio Grande do Sul" , value: "Rio Grande do Sul (RS)" },
        { key: "Rondônia" , value: "Rondônia (RO)" },
        { key: "Roraima" , value: "Roraima (RR)" },
        { key: "Santa Catarina" , value: "Santa Catarina (SC)" },
        { key: "São Paulo" , value: "São Paulo (SP)" },
        { key: "Sergipe" , value: "Sergipe (SE)" },
        { key: "Tocantins" , value: "Tocantins (TO)" },
      ],
      genderOptions:[
        { key: "masculino" , value: "Masculino" },
        { key: "feminino" , value: "Feminino" },
        { key: "outro" , value: "Outro" },
      ],
      baseProfile: {
        name: '',
        birthday: '',
        phone: '',
        gender: '',
        cpf: '',
        address: '',
        number: '',
        neighborhood: '',
        cep: '',
        city: '',
        state: '',
        user: '',
        associatedCompany: false,
        company: {
          name: '',
          cnpj: '',
          phone: '',
          stateRegister: '',
          cityRegister: '',
          address: '',
          number: '',
          neighborhood: '',
          cep: '',
          city: '',
          state: '',
        }
      },
      user: {},
    }
  },
  async mounted() {
    try {
      this.loading = true
      const sessionId = localStorage.getItem("sessionId");
      this.user = (await this.$api.get(`users/${sessionId}`)).data;

      if (!this.user.profile) {
        this.user.profile = this.baseProfile
      } else {
        this.user.profile = Object.assign(this.baseProfile, this.user.profile)
      }

      if (this.user.profile && this.user.profile.birthday) {
        this.user.profile.birthday = moment(new Date(this.user.profile.birthday)).add(3, 'hour').format('yyyy-MM-DD')
      }
      this.loading = false
    } catch (e) {
      console.error(e)
    }
  },
  async created() {
    await this.$store.dispatch('access/updateError', '')
    await this.$store.dispatch('access/updateMessage', '')
    const breadcrumb = {
      tab: '',
      page: 'Minha conta',
      subPage: {
        name: 'Perfil',
        active: true
      },
      tabs: []
    }
    await this.setBreadcrumb(breadcrumb)
  },
  computed: {
    message() {
      return this.$store.getters['access/message']
    },
    errors() {
      return this.$store.getters['access/error']
    }
  },
  methods: {
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb',
    }),
    ...mapActions('checkout', {
      checkCpf: 'checkCpf',
      checkCnpfIsDifferentUser: 'checkCnpfIsDifferentUser',
      checkCpfIsDifferentEmail: 'checkCpfIsDifferentEmail',
    }),
    ...mapActions('plan', {
      checkIfTheUserHasSignedPlan: 'checkIfTheUserHasSignedPlan',
    }),
    hasError(attr) {
      return this.error && !attr
    },

    async update() {
      try {
        await this.$store.dispatch('access/updateError', '')
        await this.$store.dispatch('access/updateMessage', '')
        this.loadingUpdate = true
        if(!this.isValid()) {
          await this.$store.dispatch('access/updateError', 'Preencha todos os campos corretamente.')
          this.loadingUpdate = false
          return
        }

        if (!this.validateInformedDateOfBirth()) {
          await this.$store.dispatch('access/updateError', 'Data de nascimento inválida!')
          this.loadingUpdate = false
          return
        }

        if (!phoneValidator(this.user.profile.phone.replace(/[^\d]/g, ''))) {
          await this.$store.dispatch('access/updateError', 'Por favor digite um telefone válido para prosseguir.')
          this.loadingUpdate = false
          return true
        }

        if (!CPF(this.user.profile.cpf)) {
          await this.$store.dispatch('access/updateError', 'CPF inválido!')
          this.loadingUpdate = false
          return true
        }

        /*const checkCpfIsDifferentEmailoBody = {
          cpf: this.user.profile.cpf.replace(/\D/g, ''),
          email: this.user.username
        }
        const { errors: errorsCpf } = await this.checkCpfIsDifferentEmail(checkCpfIsDifferentEmailoBody)
        if (errorsCpf) {
          await this.$store.dispatch('access/updateError', errorsCpf)
          return
        }*/

        if (!await this.validateCPF()) {
          await this.$store.dispatch('access/updateError', 'CPF inválido!')
          this.loadingUpdate = false
          return
        }

        if(!this.user.profile.associatedCompany){
          if (!CNPJ(this.user.profile.company.cnpj)) {
            await this.$store.dispatch('access/updateError', 'CNPJ inválido!')
            this.loadingUpdate = false
            return true
          }

          if (!phoneValidator(this.user.profile.company.phone.replace(/[^\d]/g, ''))) {
            await this.$store.dispatch('access/updateError', 'Por favor digite um telefone comercial válido para prosseguir.')
            this.loadingUpdate = false
            return true
          }

          /*const checkCpfIsDifferentEmailoBody = {
            cnpf: this.user.profile.company.cnpj.replace(/\D/g, ''),
            user: this.user.id,
          }

          const { errors: errorsCpf } = await this.checkCnpfIsDifferentUser(checkCpfIsDifferentEmailoBody)
          if (errorsCpf) {
            await this.$store.dispatch('access/updateError', errorsCpf)
            return
          }*/

          if (!await this.validateCNPJ()) {
            await this.$store.dispatch('access/updateError', 'CNPJ inválido!')
            this.loadingUpdate = false
            return
          }
        }

        const userUpdate = JSON.parse(JSON.stringify(this.user));
        if(userUpdate.profile.associatedCompany) {
          userUpdate.profile.company = {}
        } else {
          userUpdate.profile.company.cep = userUpdate.profile.company.cep.replace(/[^\d]/g, '')
          userUpdate.profile.company.cnpj = userUpdate.profile.company.cnpj.replace(/[^\d]/g, '')
          userUpdate.profile.company.phone = userUpdate.profile.company.phone.replace(/[^\d]/g, '')
        }

        if(!userUpdate.profile.user){
          userUpdate.profile.user = userUpdate._id
        }

        userUpdate.profile.birthday = new Date(userUpdate.profile.birthday).toISOString()
        userUpdate.profile.cep = userUpdate.profile.cep.replace(/[^\d]/g, '')
        userUpdate.profile.cpf = userUpdate.profile.cpf.replace(/[^\d]/g, '')
        userUpdate.profile.phone = userUpdate.profile.phone.replace(/[^\d]/g, '')

        await this.$api.put(`users/${this.user.id}`, userUpdate)
        const sessionId = localStorage.getItem("sessionId");
        const user = (await this.$api.get(`users/${sessionId}`)).data;
        await this.$store.dispatch('user/updateUser', user);
        await this.checkIfTheUserHasSignedPlan()
        this.loadingUpdate = false
        await this.$router.push('/web')
        this.loadingUpdate = false
      } catch (e) {

      }
    },

    async validateCPF() {
      try {
        //const { data: response } = await verifyCPFExists(this.user.profile.cpf.replace(/\D/g, ''), this.user.profile.birthday)
        //const { cpfExists } = response
        return true
      } catch (e) {
      }
    },

    async validateCNPJ() {
      try {
        const { data: response } = await verifyCNPJExists(this.user.profile.company.cnpj.replace(/\D/g, ''))
        const { cnpjExists } = response
        return cnpjExists
      } catch (e) {
      }
    },

    validateInformedDateOfBirth() {
      const endDate = moment(new Date()).add(-18, 'year').toDate()
      const initialDate = moment(new Date(1900, 0, 1))
      const birthDate = moment(new Date(this.user.profile.birthday))
      return birthDate.isAfter(initialDate) && birthDate.isBefore(endDate) && this.user.profile.birthday
    },

    isValid(){
      if( !this.user.profile.name ||
          !this.user.profile.birthday ||
          !this.user.profile.phone ||
          !this.user.profile.gender ||
          !this.user.profile.cpf ||
          !this.user.profile.address ||
          !this.user.profile.number ||
          !this.user.profile.neighborhood ||
          !this.user.profile.cep ||
          !this.user.profile.city ||
          !this.user.profile.state
        ) return false

      if(this.user.profile.associatedCompany === false){
        if(!this.user.profile.company.name ||
          !this.user.profile.company.cnpj ||
          !this.user.profile.company.phone ||
          !this.user.profile.company.address ||
          !this.user.profile.company.number ||
          !this.user.profile.company.neighborhood ||
          !this.user.profile.company.cep ||
          !this.user.profile.company.city ||
          !this.user.profile.company.state
        ) return false
      }

      return true
    }
  }
}
</script>

<style lang="scss">
.loading-spinner{
  margin-top: 250px;
}
.profile-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .user {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    h3 {
      font-weight: bold;
      font-size: 24px;
    }

    .inputs {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 16px;
      gap: 16px;

      div:first-child{
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }

  .company {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    h3 {
      font-weight: bold;
      font-size: 24px;
    }

    .inputs {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 16px;
      gap: 16px;

      div:first-child,div:nth-child(4), div:nth-child(5){
        grid-column-start: 1;
        grid-column-end: 4;
      }
      div:nth-child(2){
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }
}
</style>
